.ck .ck-sticky-panel .ck-sticky-panel__content_sticky {
  position: static !important;
}

.react-autosuggest__container {
  width: 100%;
  display: block;
  position: relative;
}

.react-autosuggest__container:last-child {
  margin-bottom: 3rem;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 45px;
  width: 100%;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  z-index: 4;
  border: 1px solid #eaeaea;
  border-radius: 6px;
}

.react-autosuggest__container--open:last--child
  .react-autosuggest__suggestions-container {
  margin-bottom: 3rem;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.already-selected {
  border-radius: 3px;
  animation: highlight 1000ms ease-out;
}

@keyframes highlight {
  0% {
    background-color: #fffd92;
  }
  100 {
    background-color: white;
  }
}

.react-autosuggest__selected-suggestion {
  border: 1px solid #eaeaea;
  padding: 1rem;
  position: relative;
  overflow: hidden;
}

.react-autosuggest__suggestion {
  border-color: #eaeaea !important;
}

.react-autosuggest__selected-suggestion button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.react-autosuggest__selected-suggestion {
  margin: 5px 0;
  border-radius: 6px;
}

